import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import InputWrapper from "../../../../forms/components/InputWrapper";
import InputElement, {
	CheckBoxElement,
	CurrencyInputElement,
	SelectorElement,
} from "../../../../forms/components/InputElement";
import formNavigationUtils from "utils/formNavigationUtils";
import useCurrentVehicleStore from "stores/InventoryStore";
import shallow from "zustand/shallow";
import { vehicleStatus, vehicleStatusList } from "constants/Constants";
import { useFormContext } from "react-hook-form";

const Advertising = () => {
	const [statusList, setStatusList] = useState(vehicleStatusList);
	// Vehicle store
	const {
		askingPrice,
		onlinePrice,
		wholesalePrice,
		downPmt,
		msrp,
		status,
		videoURL,
		isAdvertised,
		tag,
		editTag,
		editIsAdvertised,
		editVideoURL,
		editAskingPrice,
		editOnlinePrice,
		editWholesalePrice,
		editDownPmt,
		editMsrp,
		editStatus,
	} = useCurrentVehicleStore((state) => state, shallow);
	const updateList = () => {
		if (
			status === vehicleStatus.AVAILABLE ||
			status === vehicleStatus.HOLD ||
			status === vehicleStatus.SOLDWITHOUTDEAL ||
			status === vehicleStatus.DELETED
		) {
			// filtered list must include the current status
			const filteredList = vehicleStatusList.filter(
				(option) =>
					option.value === vehicleStatus.AVAILABLE ||
					option.value === vehicleStatus.HOLD ||
					option.value === vehicleStatus.SOLDWITHOUTDEAL ||
					option.value === vehicleStatus.DELETED
			);
			setStatusList(filteredList);
		} else if (
			status === vehicleStatus.APPRAISAL ||
			status === vehicleStatus.TRADE
		) {
			// filtered list must include the current status
			const filteredList = vehicleStatusList.filter(
				(option) =>
					option.value === vehicleStatus.AVAILABLE ||
					option.value === vehicleStatus.APPRAISAL ||
					option.value === vehicleStatus.TRADE ||
					option.value === vehicleStatus.DELETED
			);
			setStatusList(filteredList);
		} else if (
			status === vehicleStatus.SOLD ||
			status === vehicleStatus.PENDING
		) {
			setStatusList(vehicleStatusList);
		} else {
			setStatusList(vehicleStatusList);
		}
	};

	useEffect(() => {
		updateList();
		// eslint-disable-next-line
	}, [status]);

	// Define custom error messages for different error types
	const methods = useFormContext();

	return (
		<Form onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle="Advertising"
				inputComponents={[
					<SelectorElement
						{...methods}
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.PENDING
						}
						value={status}
						name="status"
						label="Status"
						type="text"
						onChange={(e) => {
							editStatus(e.target.value);
						}}
						options={statusList}
					/>,

					<CurrencyInputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						value={msrp}
						name="msrp"
						label="MSRP"
						type="text"
						onChange={(e) => {
							editMsrp(e);
						}}
					/>,
					<CurrencyInputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						value={askingPrice}
						name="price"
						label="Asking Price"
						type="text"
						onChange={(e) => {
							editAskingPrice(e);
						}}
					/>,
					<CurrencyInputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						value={onlinePrice}
						name="internet price"
						label="Internet Price"
						type="text"
						onChange={(e) => {
							editOnlinePrice(e);
						}}
					/>,
					<CurrencyInputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						value={wholesalePrice}
						name="Wholesale price"
						label="Wholesale Price"
						type="text"
						onChange={(e) => {
							editWholesalePrice(e);
						}}
					/>,

					<CurrencyInputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						value={downPmt}
						name="downPmt"
						label="Down Payment"
						type="text"
						onChange={(e) => {
							editDownPmt(e);
						}}
					/>,
					<InputElement
						{...methods}
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						colSize="col-lg-4"
						value={videoURL}
						onChange={(e) => {
							editVideoURL(e.target.value);
						}}
						name="videoURL"
						label="Video URL"
						type="text"
					/>,
					<CheckBoxElement
						{...methods}
						disabled={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						checked={isAdvertised}
						onChange={() => editIsAdvertised(!isAdvertised)}
						name="isAdvertised"
						label="Is Advertised"
						labelClass="pl-4 required"
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={tag}
						name="tag"
						label="Tagline(/Highlight)"
						type="text"
						colSize="col-6"
						onChange={(e) => {
							editTag(e.target.value);
						}}
					/>,
				]}
			/>
		</Form>
	);
};

export default Advertising;
