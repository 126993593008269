import create from "zustand";
import produce from "immer";
import { getNotesArray } from "../features/notes/NoteFunctions";
import { version } from "../version";

const useCurrentDealStore = create((set) => ({
	deal: {
		ID: null,
		buyerRecNum: null,
		coBuyerRecNum: null,
		saleRecNum: null,
		recapRecNum: null,
		carRecNum: null,
		lenderRecNum: null,
		insGapRecNum: null,
		insCreditLifeRecNum: null,
		leaseRecNum: null,
		salesmanRecNum: null,
		shippingRecNum: null,
		type: "Pending",
		countyRecNum: null,
		dealSettings: {
			profitSettings: {},
			commissionSettings: {},
			taxDefaults: {},
		},
		isTemplate: false,
		insGapName: "",
		notes: [],
		createdOn: "",
		createdById: null,
		createdBy: "",
		modifiedBy: "",
		modifiedById: null,
		modifiedOn: "",
		soldDate: "",
		deliveredDate: "",
		titleDate: "",
		repoDate: "",
		fundedDate: "",
		isFunded: false,
		dcVersion: "v" + version,
	},

	buyer: {},
	coBuyer: {},
	vehicle: {},
	county: {},
	miscSellerFees: [],
	sellerFeeTypes: [],
	miscDMVFees: [],
	dmvFeeTypes: [],
	aftermarket: [],
	calculateBool: false,
	warranty: [],
	pickupPmts: [],
	commissions: [],
	trades: [],
	buyerAddress: {},
	buyerMailAddress: {},
	coBuyerAddress: {},
	coBuyerMailAddress: {},
	buyerEmploymentHistory: [],
	coBuyerEmploymentHistory: [],
	tradeVendors: [],
	warrantyVendors: [],
	gapCoName: "",
	isLoading: true,
	contractInfo: {},
	salesPerson: {},
	collisionInsurance: {
		term: 0,
		coRecNum: null,
		appNumber: "",
		type: "",
		bodyInjuryLimit: 0,
		bodyInjuryPremium: 0,
		collisionDeductible: 0,
		collisionPremium: 0,
		compDeductible: 0,
		compPremium: 0,
		medicalLimit: 0,
		medicalPremium: 0,
		otherLimit: 0,
		otherPremium: 0,
		propertyLimit: 0,
		propertyPremium: 0,
	},
	// supportive fields that aren't in Deal.java @RequestBody
	lenders: [],
	salesPeople: [],
	lender: {},
	gapCompanies: [],
	downPayments: [],
	credit700Lenders: {},
	sellerFeeCompanies: [],
	dmvFeeCompanies: [],
	vehicleRecon: [],

	// COLLISION INSURANCE
	editCollisionInsurance: (obj) => set(() => ({ collisionInsurance: obj })),
	editTerm: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.term = e;
			})
		),

	editCoRecNum: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.coRecNum = e;
			})
		),

	editAppNumber: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.appNumber = e;
			})
		),

	editBodyInjuryLimit: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.bodyInjuryLimit = e;
			})
		),

	editBodyInjuryPremium: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.bodyInjuryPremium = e;
			})
		),
	editCollisionDeductible: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.collisionDeductible = e;
			})
		),
	editCollisionPremium: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.collisionPremium = e;
			})
		),

	editCompDeductible: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.compDeductible = e;
			})
		),

	editCompPremium: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.compPremium = e;
			})
		),
	editMedicalLimit: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.medicalLimit = e;
			})
		),

	editMedicalPremium: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.medicalPremium = e;
			})
		),
	editOtherLimit: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.otherLimit = e;
			})
		),
	editOtherPremium: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.otherPremium = e;
			})
		),
	editPropertyLimit: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.propertyLimit = e;
			})
		),
	editPropertyPremium: (e) =>
		set(
			produce((state) => {
				state.collisionInsurance.propertyPremium = e;
			})
		),

	// DEAL
	editID: (e) =>
		set(
			produce((state) => {
				state.deal.ID = e;
			})
		),
	editBuyerRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.buyerRecNum = e;
			})
		),
	editCoBuyerRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.coBuyerRecNum = e;
			})
		),
	editSaleRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.saleRecNum = e;
			})
		),
	editRecapRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.recapRecNum = e;
			})
		),
	editCarRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.carRecNum = e;
			})
		),
	editLenderRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.lenderRecNum = e;
			})
		),
	editInsGapRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.insGapRecNum = e;
			})
		),

	editInsCreditLifeRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.insCreditLifeRecNum = e;
			})
		),

	editLeaseRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.leaseRecNum = e;
			})
		),
	editSalesmanRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.salesmanRecNum = e;
			})
		),
	editShippingRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.shippingRecNum = e;
			})
		),
	editType: (e) =>
		set(
			produce((state) => {
				state.deal.type = e;
			})
		),
	editCreatedOn: (e) =>
		set(
			produce((state) => {
				state.deal.createdOn = e;
			})
		),
	editSoldDate: (e) =>
		set(
			produce((state) => {
				state.deal.soldDate = e;
			})
		),
	editCountyRecNum: (e) =>
		set(
			produce((state) => {
				state.deal.countyRecNum = e;
			})
		),
	editDealSettings: (obj) =>
		set(
			produce((state) => {
				state.deal.dealSettings = obj;
			})
		),
	editIsTemplate: (e) =>
		set(
			produce((state) => {
				state.deal.isTemplate = e;
			})
		),
	editInsGapName: (e) =>
		set(
			produce((state) => {
				state.deal.insGapName = e;
			})
		),

	// editNotes: (list) => {
	// 	console.log("editNotes called with list:", list); // Log the list passed to editNotes
	// 	set(
	// 		produce((state) => {
	// 			console.log("Previous state.notes:", state.deal.notes); // Log previous notes
	// 			state.deal.notes = list; // Ensure immutability and correct updating
	// 			console.log("Updated state.notes:", state.deal.notes); // Log updated notes
	// 		})
	// 	);
	// },
	editNotes: (newNotes, callback) => {
		set(
			produce((state) => {
				state.deal.notes = newNotes;
			}),
			false,
			"editNotes"
		);
		if (callback) callback(); // Trigger callback after the state has been updated
	},

	editDeliveredDate: (e) =>
		set(
			produce((state) => {
				state.deal.deliveredDate = e;
			})
		),
	editCreatedById: (e) =>
		set(
			produce((state) => {
				state.deal.createdById = e;
			})
		),
	editCreatedBy: (e) =>
		set(
			produce((state) => {
				state.deal.createdBy = e;
			})
		),
	editModifiedBy: (e) =>
		set(
			produce((state) => {
				state.deal.modifiedBy = e;
			})
		),
	editModifiedById: (e) =>
		set(
			produce((state) => {
				state.deal.modifiedById = e;
			})
		),
	editModifiedOn: (e) =>
		set(
			produce((state) => {
				state.deal.modifiedOn = e;
			})
		),
	editTitleDate: (e) =>
		set(
			produce((state) => {
				state.deal.titleDate = e;
			})
		),
	editRepoDate: (e) =>
		set(
			produce((state) => {
				state.deal.repoDate = e;
			})
		),
	editFundedDate: (e) =>
		set(
			produce((state) => {
				state.deal.fundedDate = e;
			})
		),
	editIsFunded: (e) =>
		set(
			produce((state) => {
				state.deal.isFunded = e;
			})
		),

	// CONTRACT INFO
	editCancellationAccepted: (e) =>
		set(
			produce((state) => {
				state.contractInfo.cancellationAccepted = e ? 1 : 0;
			})
		),

	editCancellationAmt: (e) =>
		set(
			produce((state) => {
				state.contractInfo.cancellationAmt = e;
			})
		),

	editRestockingFee: (val) =>
		set(
			produce((state) => {
				state.contractInfo.restockingFee = val;
			})
		),

	editBuyer: (obj) => set(() => ({ buyer: obj })),
	editCoBuyer: (obj) => set(() => ({ coBuyer: obj })),
	editVehicle: (obj) => set(() => ({ vehicle: obj })),
	editCounty: (obj) => set(() => ({ county: obj })),
	editMiscSellerFees: (list) => set(() => ({ miscSellerFees: list })),
	editSellerFeeTypes: (list) => set(() => ({ sellerFeeTypes: list })),
	editMiscDMVFees: (list) => set(() => ({ miscDMVFees: list })),
	editDmvFeeTypes: (list) => set(() => ({ dmvFeeTypes: list })),
	editAftermarket: (list) => set(() => ({ aftermarket: list })),
	calculate: () => set((state) => ({ calculateBool: !state.calculateBool })),
	editWarranty: (list) => set(() => ({ warranty: list })),
	editPickupPmts: (list) => set(() => ({ pickupPmts: list })),
	editCommissions: (list) => set({ commissions: list }),
	editTrades: (list) => set(() => ({ trades: list })),

	editValueInTrades: (i, key, val) =>
		set((state) => ({
			trades: state.trades.map((trade, index) => {
				if (index === i) {
					trade[key] = val;
				}
				return trade;
			}),
		})),

	editLenders: (list) => set(() => ({ lenders: list })),
	editLender: (obj) => {
		console.log("lender is changing");
		set(() => ({ lender: obj }));
	},
	editSellerFeeCompanies: (list) => set(() => ({ sellerFeeCompanies: list })),
	editDmvFeeCompanies: (list) => set(() => ({ dmvFeeCompanies: list })),

	editSalesPeople: (list) => set(() => ({ salesPeople: list })),
	editBuyerAddress: (obj) => set(() => ({ buyerAddress: obj })),
	editBuyerMailAddress: (obj) => set(() => ({ buyerMailAddress: obj })),
	editCoBuyerAddress: (obj) => set(() => ({ coBuyerAddress: obj })),
	editCoBuyerMailAddress: (obj) => set(() => ({ coBuyerMailAddress: obj })),
	editBuyerEmploymentHistory: (list) =>
		set(() => ({ buyerEmploymentHistory: list })),
	editCoBuyerEmploymentHistory: (list) =>
		set(() => ({ coBuyerEmploymentHistory: list })),
	editGapCoName: (e) => set(() => ({ gapCoName: e })),
	editTradeVendors: (list) => set(() => ({ tradeVendors: list })),
	editWarrantyVendors: (list) => set(() => ({ warrantyVendors: list })),
	editIsLoading: (e) => set(() => ({ isLoading: e })),
	editContractInfo: (obj) => set(() => ({ contractInfo: obj })),
	editSalesPerson: (obj) => set(() => ({ salesPerson: obj })),
	editGapCompanies: (list) => set(() => ({ gapCompanies: list })),
	editDownPayments: (list) => set(() => ({ downPayments: list })),
	editCredit700Lenders: (obj) => set(() => ({ credit700Lenders: obj })),
	editVehicleRecon: (list) => set(() => ({ vehicleRecon: list })),

	/**
	 * Updates settings to support possible new values. All defaults are overwritten
	 * by existing deals settings with the exception of new settings that have
	 * been added to the system since they will not exist for the deal.
	 *
	 * Must be called after setDealFields()
	 *
	 * @param {obj} defaults the company default settings, could include new
	 * 						 settings added to the system
	 * @param {obj} current  the deals current settings
	 */
	updateSettings: (defaults, current) => {
		// Create a new object for completeSettings to ensure we're not modifying any existing objects
		let completeSettings = {
			taxDefaults: {
				// Ensure taxRegion and taxRate are always initialized
				taxRegion: 2,
				taxRate: 0,
				...defaults.taxDefaults,
				...(current?.taxDefaults || {}),
			},
			profitSettings: {
				...defaults.profitSettings,
				...(current?.profitSettings || {}),
			},
			commissionSettings: {
				...defaults.commissionSettings,
				...(current?.commissionSettings || {}),
			},
		};

		console.log(completeSettings);

		set(
			produce((state) => {
				if (current === null) {
					// We don't need to set taxRegion here anymore as it's already initialized
					completeSettings.taxDefaults.taxRate = state.deal.sale.taxRate || 0;
				}
				// Create a new object for dealSettings to ensure we're not modifying the existing one
				state.deal.dealSettings = { ...completeSettings };
			})
		);
	},

	editTaxDefaults: (obj) =>
		set(
			produce((state) => {
				state.deal.dealSettings.taxDefaults = obj;
			})
		),

	editProfitSettings: (obj) =>
		set(
			produce((state) => {
				state.deal.dealSettings.profitSettings = obj;
			})
		),

	editCommissionSettings: (obj) =>
		set(
			produce((state) => {
				state.deal.dealSettings.commissionSettings = obj;
			})
		),

	editTaxDefaultsValue: (key, val) =>
		set(
			produce((state) => {
				state.deal.dealSettings.taxDefaults = {
					...state.deal.dealSettings.taxDefaults,
					[key]: val,
				};
			})
		),

	editProfitSettingsValue: (key, val) =>
		set(
			produce((state) => {
				state.deal.dealSettings.profitSettings = {
					...state.deal.dealSettings.profitSettings,
					[key]: val,
				};
			})
		),

	editCommissionSettingsValue: (key, val) =>
		set(
			produce((state) => {
				state.deal.dealSettings.commissionSettings = {
					...state.deal.dealSettings.commissionSettings,
					[key]: val,
				};
			})
		),

	/**
	 * Sets deal fields by utilizing the spread operator. This should be called before
	 * updating the dealSettings since this would overwrite them back to a JSON string
	 *
	 * @param {*} res the response from getDealApi, pass in 'res.data.content'
	 */
	setDealFields: (res) => {
		console.log("setting the store");
		set(() => ({
			...res,
			deal: {
				...res.deal,
				dealSettings: JSON.parse(res.deal.dealSettings),
				notes: getNotesArray(res.deal.notes),
			},
		}));
	},

	reset: () => {
		console.log("deal reset");
		set({
			deal: {
				ID: null,
				buyerRecNum: null,
				coBuyerRecNum: null,
				saleRecNum: null,
				recapRecNum: null,
				carRecNum: null,
				lenderRecNum: null,
				insGapRecNum: null,
				insCreditLifeRecNum: null,
				leaseRecNum: null,
				salesmanRecNum: null,
				shippingRecNum: null,
				type: "Pending",
				createdOn: "",
				soldDate: "",
				countyRecNum: null,
				dealSettings: {
					profitSettings: {},
					commissionSettings: {},
					taxDefaults: {},
				},
				isTemplate: false,
				insGapName: "",
				notes: [],
				deliveredDate: "",
				createdById: null,
				createdBy: "",
				modifiedBy: "",
				modifiedById: null,
				modifiedOn: "",
				titleDate: "",
				repoDate: "",
				fundedDate: "",
				isFunded: false,
			},
			buyer: {},
			coBuyer: {},
			vehicle: {},
			county: {},
			miscSellerFees: [],
			sellerFeeTypes: [],
			miscDMVFees: [],
			dmvFeeTypes: [],
			aftermarket: [],
			warranty: [],
			pickupPmts: [],
			commissions: [],
			trades: [],
			buyerAddress: {},
			buyerMailAddress: {},
			coBuyerAddress: {},
			coBuyerMailAddress: {},
			buyerEmploymentHistory: [],
			coBuyerEmploymentHistory: [],
			tradeVendors: [],
			warrantyVendors: [],
			gapCoName: "",
			isLoading: true,
			contractInfo: {},
			salesPerson: {},
			lenders: [],
			salesPeople: [],
			lender: {},
			gapCompanies: [],
			downPayments: [],
			credit700Lenders: {},
			collisionInsurance: {
				term: 0,
				coRecNum: null,
				appNumber: "",
				type: "",
				bodyInjuryLimit: 0,
				bodyInjuryPremium: 0,
				collisionDeductible: 0,
				collisionPremium: 0,
				compDeductible: 0,
				compPremium: 0,
				medicalLimit: 0,
				medicalPremium: 0,
				otherLimit: 0,
				otherPremium: 0,
				propertyLimit: 0,
				propertyPremium: 0,
			},
			sellerFeeCompanies: [],
			dmvFeeCompanies: [],
			vehicleRecon: [],
		});
	},
}));

export default useCurrentDealStore;
